// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MediumImage_box__xUcKi {
  position: relative;
  max-height: 350px;
  max-width: 350px;
}

.MediumImage_box__xUcKi img {
  max-height: 350px;
  max-width: 350px;
}

.MediumImage_shadow__i2MHn {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 50;
}

.MediumImage_shadow__i2MHn:hover {
  box-shadow: inset 0px -50px 30px 10px rgba(121, 121, 121, 0.4);
}
`, "",{"version":3,"sources":["webpack://./src/components/mediumImage/MediumImage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,YAAY;EACZ,WAAW;EACX,WAAW;AACb;;AAEA;EACE,8DAA8D;AAChE","sourcesContent":[".box {\n  position: relative;\n  max-height: 350px;\n  max-width: 350px;\n}\n\n.box img {\n  max-height: 350px;\n  max-width: 350px;\n}\n\n.shadow {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  height: 100%;\n  width: 100%;\n  z-index: 50;\n}\n\n.shadow:hover {\n  box-shadow: inset 0px -50px 30px 10px rgba(121, 121, 121, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `MediumImage_box__xUcKi`,
	"shadow": `MediumImage_shadow__i2MHn`
};
export default ___CSS_LOADER_EXPORT___;
