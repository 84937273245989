// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FoundUserItem_search-result__5kFkz {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  height: 50px;
  width: 450px;
}

.FoundUserItem_profile-picture__9tPZZ {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.FoundUserItem_user-details__JZm-V {
  flex: 1 1;
}

.FoundUserItem_user-name__8-O\\+m {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.FoundUserItem_dropdown__6ZhZe {
  position: relative;
}

.FoundUserItem_dropdown-btn__AUgLN {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.FoundUserItem_dropdown-content__fDXnu {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  right: 0;
  width: 160px; /* Adjusted width */
}

.FoundUserItem_dropdown-content__fDXnu a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
}

.FoundUserItem_dropdown-content__fDXnu a:hover {
  background-color: #f2f2f2;
}

.FoundUserItem_dropdown__6ZhZe:hover .FoundUserItem_dropdown-content__fDXnu {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/foundUserItem/FoundUserItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,wCAAwC;EACxC,kBAAkB;EAClB,QAAQ;EACR,YAAY,EAAE,mBAAmB;AACnC;;AAEA;EACE,cAAc;EACd,aAAa;EACb,WAAW;EACX,qBAAqB;EACrB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".search-result {\n  display: flex;\n  align-items: center;\n  padding: 15px;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  margin-bottom: 15px;\n  height: 50px;\n  width: 450px;\n}\n\n.profile-picture {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  margin-right: 15px;\n  object-fit: cover;\n}\n\n.user-details {\n  flex: 1;\n}\n\n.user-name {\n  font-size: 16px;\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n.dropdown {\n  position: relative;\n}\n\n.dropdown-btn {\n  background-color: #4caf50;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 12px;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.dropdown-content {\n  display: none;\n  position: absolute;\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  right: 0;\n  width: 160px; /* Adjusted width */\n}\n\n.dropdown-content a {\n  display: block;\n  padding: 10px;\n  color: #333;\n  text-decoration: none;\n  transition: background-color 0.3s;\n}\n\n.dropdown-content a:hover {\n  background-color: #f2f2f2;\n}\n\n.dropdown:hover .dropdown-content {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-result": `FoundUserItem_search-result__5kFkz`,
	"profile-picture": `FoundUserItem_profile-picture__9tPZZ`,
	"user-details": `FoundUserItem_user-details__JZm-V`,
	"user-name": `FoundUserItem_user-name__8-O+m`,
	"dropdown": `FoundUserItem_dropdown__6ZhZe`,
	"dropdown-btn": `FoundUserItem_dropdown-btn__AUgLN`,
	"dropdown-content": `FoundUserItem_dropdown-content__fDXnu`
};
export default ___CSS_LOADER_EXPORT___;
