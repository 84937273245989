import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProfilePage from "scenes/profilePage";
import UserPage from "scenes/userPage";

const IsIdEqualToUserWrapper = () => {
  const userId = useSelector((state) => state.user);
  const params = useParams();
  const userIdParameter = params.userId;
  if (userId === userIdParameter) {
    return <ProfilePage />;
  } else {
    return <UserPage />;
  }
};

export default IsIdEqualToUserWrapper;
