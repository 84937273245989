import React from "react";
import styles from "./GalleryItemSkeleton.module.css";

const GalleryItemSkeleton = () => {
  return (
    <div
      className={styles["skeleton"]}
      style={{ height: "200px", width: "300px" }}
    ></div>
  );
};

export default GalleryItemSkeleton;
