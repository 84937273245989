// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GalleryItem_checkbox-container__Vdu\\+U {
  position: absolute;
  top: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/galleryItem/GalleryItem.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT","sourcesContent":[".checkbox-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": `GalleryItem_checkbox-container__Vdu+U`
};
export default ___CSS_LOADER_EXPORT___;
