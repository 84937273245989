// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddSinglePicture_upload-card__gAIYn {
  max-width: 400px;
  padding: 20px;
  background: linear-gradient(to right, #f7f7f7, #e0e0e0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
}

.AddSinglePicture_title__Fzv9m {
  color: #4caf50;
  margin-bottom: 20px;
  font-size: 24px;
}

.AddSinglePicture_upload-input__qv9k6 {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  font-size: 16px;
}

.AddSinglePicture_buttons__k3uh7 {
  display: flex;
  justify-content: space-between;
}

.AddSinglePicture_confirm-btn__RBQCC,
.AddSinglePicture_cancel-btn__BSDSl {
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.AddSinglePicture_confirm-btn__RBQCC {
  background-color: #4caf50;
  color: #fff;
}

.AddSinglePicture_cancel-btn__BSDSl {
  background-color: #ddd;
  color: #333;
}

.AddSinglePicture_confirm-btn__RBQCC:hover,
.AddSinglePicture_cancel-btn__BSDSl:hover {
  background-color: #555;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/addSinglePicture/AddSinglePicture.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uDAAuD;EACvD,wCAAwC;EACxC,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;;EAEE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;;EAEE,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".upload-card {\n  max-width: 400px;\n  padding: 20px;\n  background: linear-gradient(to right, #f7f7f7, #e0e0e0);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 12px;\n  text-align: center;\n}\n\n.title {\n  color: #4caf50;\n  margin-bottom: 20px;\n  font-size: 24px;\n}\n\n.upload-input {\n  width: 100%;\n  padding: 12px;\n  margin-bottom: 20px;\n  border: 1px solid #ddd;\n  border-radius: 6px;\n  background-color: #fff;\n  font-size: 16px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.confirm-btn,\n.cancel-btn {\n  padding: 12px 20px;\n  border: none;\n  border-radius: 6px;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.confirm-btn {\n  background-color: #4caf50;\n  color: #fff;\n}\n\n.cancel-btn {\n  background-color: #ddd;\n  color: #333;\n}\n\n.confirm-btn:hover,\n.cancel-btn:hover {\n  background-color: #555;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-card": `AddSinglePicture_upload-card__gAIYn`,
	"title": `AddSinglePicture_title__Fzv9m`,
	"upload-input": `AddSinglePicture_upload-input__qv9k6`,
	"buttons": `AddSinglePicture_buttons__k3uh7`,
	"confirm-btn": `AddSinglePicture_confirm-btn__RBQCC`,
	"cancel-btn": `AddSinglePicture_cancel-btn__BSDSl`
};
export default ___CSS_LOADER_EXPORT___;
