import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Navbar from "scenes/navbar";

function Message({ content }) {
  return <p>{content}</p>;
}

const PaymentSite = () => {
  const initialOptions = {
    "client-id":
      "AaiV2cja0d10T7N4DOXiiG3XQZLjL5VYGMAW59cU-1qSKw4qiFIQuajxWCv0-DedXty-KziK0Yu-Okzq",
    "enable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };

  const [message, setMessage] = useState("");

  const token = useSelector((state) => state.token);
  const username = useSelector((state) => state.username);
  const user_id = useSelector((state) => state.user);
  const navigate = useNavigate();
  const params = useParams();
  const planType = params.planType;

  function createOrder() {
    return fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/create-paypal-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          mode: "cors",
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
          cart: [
            {
              id: "P-6PL64158YB3580901MVVE4XY",
            },
          ],
        }),
      }
    )
      .then((response) => response.json())
      .then((order) => order.data);
  }

  return (
    <div>
      <Navbar />
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: "P-6PL64158YB3580901MVVE4XY",
              custom_id: user_id,
            });
          }}
          onApprove={async (data, actions) => {
            console.log("Approved", data);

            if (data.orderID) {
              setMessage(
                `You have successfully subscribed to the plan. Your subscription id is: ${data.subscriptionID}`
              );
            } else {
              setMessage(
                `Failed to activate the subscription: ${data.subscriptionID}`
              );
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
};

export default PaymentSite;
