import React, { useEffect, useState } from "react";
import DefaultPicture from "./blank-profile-picture.webp";
import { useSelector } from "react-redux";
import styles from "./FoundUserItem.module.css";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "components/ConfirmDelete";

const FoundUserItem = ({ user, removeFriend, addFriend }) => {
  const [userPicture, setUserPicture] = useState("");
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const navigate = useNavigate();

  const fetchPersonPicture = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${user["user_id"]}/profile_picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setUserPicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (user["picture_path"]) {
      fetchPersonPicture();
    }
  }, []);

  return (
    <>
      {confirmRemove && (
        <ConfirmDelete
          functionAfterConfirm={removeFriend}
          setIsOpen={setConfirmRemove}
          text={`Do you want to remove ${user["username"]} from friends?`}
          funcParams={user["user_id"]}
        />
      )}
      <div className={styles["search-result"]}>
        <img
          src={userPicture || DefaultPicture}
          alt={`${user["username"]}'s profile`}
          className={styles["profile-picture"]}
        />
        <div className={styles["user-details"]}>
          <div className={styles["user-name"]}>{user["username"]}</div>
        </div>
        <div className={styles["dropdown"]}>
          <button className={styles["dropdown-btn"]}>Options</button>
          <div className={styles["dropdown-content"]}>
            {user["is_friend"] ? (
              <a onClick={() => addFriend(user["user_id"])}>Add to Friends</a>
            ) : (
              <a onClick={() => setConfirmRemove(true)}>Remove Friend</a>
            )}
            <a href={`/userPage/${user["user_id"]}`}>View Profile</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FoundUserItem;
