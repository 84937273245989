import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import "./index.css";
import { useSelector } from "react-redux";
import PersonPicture from "./blank-profile-picture.webp";
import AddSinglePicture from "components/addSinglePicture";
import GalleryItem from "components/galleryItem";
import Field from "../../components/field/Field";
import MediumImage from "components/mediumImage";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-1487-svgrepo-com.svg";

const PersonPage = () => {
  const params = useParams();
  const personId = params.personId;
  const token = useSelector((state) => state.token);
  const [personInfo, setPersonInfo] = useState({});
  const [stories, setStories] = useState([]);
  const [errror, setError] = useState("");
  const [photos, setPhotos] = useState([]);
  const [filenames, setFilenames] = useState([]);
  const [images, setImages] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [uploadPersonPicture, setUploadPersonPicture] = useState(false);
  const [personPicture, setPersonPicture] = useState("");
  const navigate = useNavigate();

  const fetchProfilePicture = async (filename) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${personId}/picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setPersonPicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const getPersonInfo = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${personId}/full`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const person_informations = await res.json();
      if (res.status !== 200) {
        return;
      }
      console.log(person_informations);
      if (person_informations["person"]["picture_path"]) {
        fetchProfilePicture(person_informations["person"]["picture_path"]);
      }
      setPersonInfo(person_informations["person"]);
      setImages(person_informations["images_with_person"]);
      setStories(person_informations["stories"]);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const deletePerson = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${personId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDeleteConfirmation(false);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    getPersonInfo();
  }, []);

  return (
    <>
      <Navbar />
      {deleteConfirmation && (
        <div className="delete-confirmation">
          <div className="confirmation-card">
            <div className="confirmation-text">
              Are you sure you want to delete this person?
            </div>
            <div className="confirmation-buttons">
              <button
                className="confirm-buttons"
                onClick={() => setDeleteConfirmation(false)}
              >
                No
              </button>
              <button
                className="confirm-buttons confirm-delete"
                onClick={() => deletePerson()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="person-page-container">
        <div className="picture-info-container">
          <div
            className="image-container"
            onClick={() => setUploadPersonPicture(true)}
          >
            {/* <img
              className="person-picture"
              src={personPicture || PersonPicture}
              alt="Person Picture"
            /> */}
            <MediumImage url={personPicture} />
          </div>
          {uploadPersonPicture && (
            <div
              style={{ position: "fixed", top: "0", left: "0", zIndex: "10" }}
            >
              <AddSinglePicture
                type="person"
                personId={personId}
                setIsOpen={setUploadPersonPicture}
              />
            </div>
          )}
          <div className="info-container">
            <table style={{ borderSpacing: "10px" }}>
              <tr>
                <Field
                  name="First Name"
                  endpoint="first_name"
                  valueState={personInfo?.["first_name"]}
                  type="person"
                  person_id={personId}
                />
              </tr>
              <tr>
                <Field
                  name="Second Name"
                  endpoint="second_name"
                  valueState={personInfo?.["second_name"]}
                  type="person"
                  person_id={personId}
                />
              </tr>
              <tr>
                <Field
                  name="Last Name"
                  endpoint="last_name"
                  valueState={personInfo?.["last_name"]}
                  type="person"
                  person_id={personId}
                />
              </tr>
              <tr>
                <Field
                  name="Birth Date"
                  endpoint="birth_date"
                  valueState={personInfo?.["birth_date"]}
                  type="person"
                  person_id={personId}
                  inputType="date"
                />
              </tr>
              <tr>
                <Field
                  name="Death Date"
                  endpoint="death_date"
                  valueState={personInfo?.["death_date"]}
                  type="person"
                  person_id={personId}
                  inputType="date"
                />
              </tr>
              <tr>
                <Field
                  name="Description"
                  endpoint="description"
                  valueState={personInfo?.["description"]}
                  type="person"
                  person_id={personId}
                />
              </tr>
            </table>
            <button className="my-button-delete" onClick={() => deletePerson()}>
              Delete{" "}
              <span>
                <DeleteIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="stories-photos-container">
          <div className="stories-container">
            <p>Stories</p>
            <div className="stories">
              {stories.map((story) => {
                return (
                  <div
                    className="story"
                    onClick={() => navigate(`/story/${story["story_id"]}`)}
                  >
                    {story["title"]}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="photos-container">
            {images.map((image) => {
              return (
                <div
                  className="person-photo"
                  onClick={() =>
                    navigate(`/photoPage/${image["aws_filename"]}`)
                  }
                >
                  <GalleryItem imageFilename={image["aws_filename"]} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonPage;
