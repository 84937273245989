import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const EmailVerification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const verifyEmail = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/email/verification/verify`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            token: searchParams.get("verify_token"),
          }),
        }
      );
      if (res.status === 429) {
        setMessage("Too many requests");
      } else if (res.status !== 200) {
        setMessage("Token is invalid or expired");
      } else {
        setMessage("Email is verified");
      }
    } catch {
      console.log("Error");
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {message}
      </div>
    </>
  );
};

export default EmailVerification;
