import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./PhotoInformationCard.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AssignPersonItem from "components/asignPersonItem";

const PhotoInformationsCard = ({
  photo,
  person,
  faceId,
  locationInformations,
  setAssignTrigger,
}) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const [error, setError] = useState(false);
  const [assignDropdown, setAssignDropdown] = useState(false);
  const [usersPeople, setUsersPeople] = useState([]);
  const [usersPeopleOffset, setUsersPeopleOffset] = useState(0);
  const [loadingPeople, setLoadingPeople] = useState(false);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setUsersPeopleOffset((curr) => curr + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingPeople]
  );

  const getUsersPeople = async () => {
    try {
      setLoadingPeople(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/${user_id}/people/?offset=${usersPeopleOffset}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const people = (await res.json())["data"];
      setUsersPeople((curr) => [...curr, ...people]);
      setLoadingPeople(false);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    getUsersPeople();
  }, [usersPeopleOffset]);

  const removeAssignment = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/assign/${faceId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  return (
    <div className={styles["informations-container"]}>
      {faceId && (
        <button
          className={styles["face-search-button"]}
          onClick={() => {
            if (faceId) {
              navigate(
                `/searchFaces/?faceId=${faceId}&awsFilename=${photo["aws_filename"]}`
              );
            }
          }}
        >
          Search For This Face
        </button>
      )}

      <div className={styles["photo-informations"]}>
        <p>Photo Informations</p>
        <span>
          <span>Owner: </span>
          <span
            onClick={() => navigate(`/userPage/${photo["owner"]}`)}
            class={styles["owner"]}
          >
            {photo?.["owner_username"]}
          </span>
        </span>

        {photo?.["photo_location"] ? (
          <span>
            <span>
              Photo location: {locationInformations["city"]},{" "}
              {locationInformations["state"]}, {locationInformations["country"]}
            </span>
          </span>
        ) : (
          <span>
            <span>Photo location: </span>
            <span class={styles["unknown-value"]}>Unknown</span>
          </span>
        )}
        {photo["when_taken"] ? (
          <span>When taken: {photo["when_taken"]}</span>
        ) : (
          <span>
            <span>When taken: </span>
            <span class={styles["unknown-value"]}>Unknown</span>
          </span>
        )}
        <span>
          <span>Description: </span>
          {photo["description"] ? (
            <span>{photo["description"]}</span>
          ) : (
            <span class={styles["unknown-value"]}>Unknown</span>
          )}
        </span>
      </div>
      <div className={styles["person-informations"]}>
        <p>Person Informations</p>
        {faceId ? (
          <>
            {person["first_name"] ? (
              <span>
                <span>First name: {person["first_name"]}</span>
              </span>
            ) : (
              <span>
                <span>First name: </span>
                <span class={styles["unknown-value"]}>Unknown</span>
              </span>
            )}
            {person["last_name"] ? (
              <span>
                <span>Last name: {person["last_name"]}</span>
              </span>
            ) : (
              <span>
                <span>Last name: </span>
                <span class={styles["unknown-value"]}>Unknown</span>
              </span>
            )}
            {person["birth_date"] ? (
              <span>
                <span>Birth date: {person["birth_date"]}</span>
              </span>
            ) : (
              <span>
                <span>Birth date: </span>
                <span class={styles["unknown-value"]}>Unknown</span>
              </span>
            )}

            {person["person_id"] ? (
              <div className={styles["buttons-container"]}>
                <button
                  onClick={() => navigate(`/personPage/${person["person_id"]}`)}
                >
                  See this person
                </button>
                <button onClick={() => removeAssignment()}>
                  Remove assignment
                </button>
              </div>
            ) : (
              <div className={styles["buttons-container"]}>
                <div className={styles["assign-container"]}>
                  <button onClick={() => setAssignDropdown((curr) => !curr)}>
                    Assign this person
                  </button>
                  {assignDropdown && (
                    <div className={styles["assign-dropdown"]}>
                      <button
                        onClick={() =>
                          navigate(`/createNewPerson/?faceId=${faceId}`)
                        }
                      >
                        Create new person
                      </button>
                      {usersPeople ? (
                        <div className={styles["users-people-to-select"]}>
                          {/* {usersPeople?.map((person, index) => {
                            if (index + 1 == usersPeopleOffset + 10) {
                              return (
                                <div
                                  onClick={() =>
                                    assignPersonToFace(person["person_id"])
                                  }
                                  className={styles["user-to-select"]}
                                  ref={lastBookElementRef}
                                >
                                  <span>
                                    {person["first_name"] || "Unknown"}
                                  </span>
                                  <span>
                                    {person["last_name"] || "Unknown"}
                                  </span>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  className={styles["user-to-select"]}
                                  onClick={() =>
                                    assignPersonToFace(person["person_id"])
                                  }
                                >
                                  <span>
                                    {person["first_name"] || "Unknown"}
                                  </span>
                                  <span>
                                    {person["last_name"] || "Unknown"}
                                  </span>
                                </div>
                              );
                            }
                          })} */}
                          {usersPeople.map((person, index) => {
                            if (index + 1 == usersPeopleOffset + 10) {
                              return (
                                <div ref={lastBookElementRef}>
                                  <AssignPersonItem
                                    person={person}
                                    index={index}
                                    faceId={faceId}
                                    setAssignTrigger={setAssignTrigger}
                                    setAssignDropdown={setAssignDropdown}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div>
                                  <AssignPersonItem
                                    person={person}
                                    index={index}
                                    faceId={faceId}
                                    setAssignTrigger={setAssignTrigger}
                                    setAssignDropdown={setAssignDropdown}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      ) : (
                        <div>You don't have any people added yet</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <span id={styles["no-person-choosen"]}>No person chosen</span>
        )}
      </div>
    </div>
  );
};

export default PhotoInformationsCard;
