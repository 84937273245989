import React from "react";
import { useNavigate } from "react-router-dom";

const AlbumItem = ({ album, index }) => {
  const navigate = useNavigate();

  return (
    <tr
      style={{
        height: "150px",
        background: index % 2 ? "#e3a9a6" : "#bf8e8c",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/album/${album["album_id"]}`)}
    >
      <td
        style={{
          textAlign: "center",
          borderRight: "1px solid #d1e6fa",
        }}
      >
        {index + 1}
      </td>
      <td
        style={{
          textAlign: "center",
          borderRight: "1px solid #d1e6fa",
        }}
      >
        {album["name"]}
      </td>
      <td
        style={{
          textAlign: "center",
          borderRight: "1px solid #d1e6fa",
        }}
      >
        {album["photos"]?.length || 0}
      </td>
      <td
        style={{
          textAlign: "center",
          borderRight: "1px solid #d1e6fa",
        }}
      >
        {album["who_can_view"]}
      </td>
      <td
        style={{
          height: "100%",
          textAlign: "center",
          borderRight: "1px solid #d1e6fa",
        }}
      >
        <button
          style={{
            width: "80%",
            height: "60px",
            background: "#e3bea6",
            border: "none",
            cursor: "pointer",
            fontSize: "20px",
            color: "#282d2e",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/menageAlbum/${album["album_id"]}`);
          }}
        >
          Menage
        </button>
      </td>
    </tr>
  );
};

export default AlbumItem;
