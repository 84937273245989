import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { ReactComponent as NotificationIcon } from "./notifications-outline_3.svg";
import { ReactComponent as ArrowDown } from "./down-arrow-svgrepo-com.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLogout, setCurrentFamily } from "state";
import { useNavigate } from "react-router-dom";
import userIcon from "./blank-profile-picture.webp";
import "./index.css";
import NotificationsList from "components/notificationsList";
import Error from "components/Error";

const RegularNavbar = () => {
  const [notifications, setNotifications] = useState([]);
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const username = useSelector((state) => state.username);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [galleryDropdown, setGalleryDropdown] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [notificationsDropdown, setNotificationsDropdown] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [notificationPageNumber, setNotificationPageNumber] = useState(1);
  const [error, setError] = useState("");
  const ref = useRef(null);
  const notificationsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setProfileDropdown(false);
    }
  };

  const handleClickOutsideNotifications = (event) => {
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(event.target)
    ) {
      setNotificationsDropdown(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/notifications?page_number=${notificationPageNumber}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        setNotifications([]);
        return;
      }
      setNotifications([]);
      setNotifications(await res.json());
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchProfilePicture = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${user_id}/profile_picture?is_thumbnail=true`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => setProfilePicture(URL.createObjectURL(blob)));
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideNotifications, true);
    return () => {
      document.removeEventListener(
        "click",
        handleClickOutsideNotifications,
        true
      );
    };
  }, []);

  useEffect(() => {
    fetchProfilePicture();
    fetchNotifications();
  }, []);

  return (
    <div className="navbar-container">
      {error && <Error message={error} closingFunction={setError} />}
      <nav>
        <div
          style={{
            backgroundColor: "#8FD3E3",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "90px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "20px",
              cursor: "pointer",
              color: "white",
              height: "100%",
              width: "130px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10%",
            }}
            onClick={() => navigate("/")}
          >
            FamilyPhotos
            {console.log(notifications)}
          </div>
          <div style={{ display: "flex", height: "100%" }}>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                color: "white",
                height: "100%",
                width: "150px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onMouseEnter={() => setGalleryDropdown(true)}
              onMouseLeave={() => setGalleryDropdown(false)}
            >
              Gallery
              {galleryDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "90px",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 9,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      minWidth: "150px",
                      height: "90px",
                    }}
                    onClick={() => navigate("/photoGallery")}
                  >
                    View photos
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      minWidth: "150px",
                      height: "90px",
                    }}
                    onClick={() => navigate("/albums")}
                  >
                    Albums
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                color: "white",
                display: "flex",
                height: "100%",
                width: "150px",
                justifyContent: "center",
                alignItems: "center",
                gap: "0",
              }}
              onMouseEnter={() => setSearchDropdown(true)}
              onMouseLeave={() => setSearchDropdown(false)}
            >
              Search
              {searchDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "90px",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 9,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      minWidth: "150px",
                      height: "90px",
                    }}
                    onClick={() => navigate("/searchFaces")}
                  >
                    Search Faces
                  </div>
                  <div
                    onClick={() => navigate("/photoGeoSearch")}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      minWidth: "150px",
                      height: "90px",
                    }}
                  >
                    GeoSearch Photos
                  </div>
                  <div
                    onClick={() => navigate("/photoSearch")}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      minWidth: "150px",
                      height: "90px",
                    }}
                  >
                    Search Photos
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      color: "white",
                      minWidth: "150px",
                      height: "90px",
                    }}
                    onClick={() => navigate("/userSearch")}
                  >
                    Search Users
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      color: "white",
                      minWidth: "150px",
                      height: "90px",
                    }}
                    onClick={() => navigate("/peopleSearch")}
                  >
                    Search People
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              color: "white",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "150px",
              cursor: "pointer",
            }}
            onClick={() => {
              setNotificationsDropdown((curr) => !curr);
            }}
          >
            <NotificationIcon className="notification-icon" />

            {notificationsDropdown && (
              <NotificationsList
                notifications={notifications}
                error={error}
                notificationsRef={notificationsRef}
              />
            )}
          </div>
          <div
            className="profile"
            onClick={() => setProfileDropdown((curr) => !curr)}
            ref={ref}
          >
            <div className="icon-username">
              {/* {console.log(profilePicture, "PP")} */}
              <img src={profilePicture || userIcon} alt="User Icon" />
              <p>{username}</p>
              <ArrowDown className="arrow-down" />
            </div>
            <div className="profile-dropdown">
              {profileDropdown && (
                <>
                  <div
                    onClick={() => {
                      navigate("/profilePage");
                    }}
                    className="profile-dropdown-option"
                  >
                    User
                  </div>
                  <hr />
                  <div
                    onClick={() => {
                      navigate("/settings");
                    }}
                    className="profile-dropdown-option"
                  >
                    Settings
                  </div>
                  <hr />
                  <div
                    onClick={() => {
                      dispatch(setLogout());
                      navigate("/login");
                    }}
                    className="profile-dropdown-option"
                  >
                    Log Out
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default RegularNavbar;
