import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import Error from "components/Error";
import GalleryItem from "components/galleryItem";
import CurrentPageInfo from "components/CurrentPageInfo";
import styles from "./PhotoSearch.module.css";
import { display, width } from "@mui/system";

const PhotoSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams({ q: "" });
  const query = searchParams.get("q");
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState("");

  const SearchPhotos = async () => {
    if (!query) {
      return;
    }
    try {
      setPhotos([]);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/photos?query=${query}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const temp = await res.json();
      console.log(temp);
      if (!temp?.[0]) {
        return "No photos found";
      }
      setPhotos(temp);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (query) {
      SearchPhotos();
    }
  }, []);

  const onSubmit = () => {
    SearchPhotos();
  };

  return (
    <div>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      <div className="page-container">
        <CurrentPageInfo
          pageName="Photo Search"
          error={error}
          setError={setError}
        />
        <div
          styles={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={styles["input-container"]}>
            <input
              placeholder="Search Users..."
              className={styles["user-input"]}
              value={query}
              onChange={(e) =>
                setSearchParams(
                  (curr) => {
                    curr.set("q", e.target.value);
                    return curr;
                  },
                  { replace: true }
                )
              }
            />
            <button
              className={styles["search-button"]}
              onClick={() => onSubmit()}
            >
              Search
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
            gap: "30px",
          }}
        >
          {photos ? (
            photos?.map((photo) => {
              return (
                <a
                  href={`/photoPage/${photo["aws_filename"]}`}
                  style={{
                    textDecoration: "inherit",
                    color: "inherit",
                    cursor: "auto",
                    userSelect: "text",
                  }}
                  draggable="false"
                  key={photo["aws_filename"]}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: "400px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <GalleryItem imageFilename={photo["aws_filename"]} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div style={{ fontSize: "23px" }}>
                        {photo["photo_name"] || "No name yet"}
                      </div>
                      <div style={{ fontSize: "20px" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ width: "140px" }}>Description: </td>
                              <td style={{ minWidth: "140px" }}>
                                {photo["description"]}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "140px" }}>When Taken: </td>
                              <td style={{ minWidth: "140px" }}>
                                {photo["when_taken"] || "Unknown"}
                              </td>
                              <td style={{ width: "140px" }}>Owner: </td>
                              <td style={{ minWidth: "140px" }}>
                                {photo["owner"]}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "140px" }}>
                                Photo Location:{" "}
                              </td>
                              <td style={{ minWidth: "140px" }}>
                                {photo["photo_location"] || "Unknown"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })
          ) : (
            <div>No images to show</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotoSearch;
