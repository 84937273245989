// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SavedPhotos_photos-container__g7XEI {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  gap: 5px;
}

.SavedPhotos_saved-photos-container__HyI\\+G {
  cursor: pointer;
}

.SavedPhotos_no-photos-container__Qg1G5 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/savedPhotos/SavedPhotos.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".photos-container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin-left: 20px;\n  gap: 5px;\n}\n\n.saved-photos-container {\n  cursor: pointer;\n}\n\n.no-photos-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photos-container": `SavedPhotos_photos-container__g7XEI`,
	"saved-photos-container": `SavedPhotos_saved-photos-container__HyI+G`,
	"no-photos-container": `SavedPhotos_no-photos-container__Qg1G5`
};
export default ___CSS_LOADER_EXPORT___;
