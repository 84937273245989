import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotificationsList = ({ notifications, error }) => {
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  const notificationClicked = async (url, notificationId) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/notification/${notificationId}/click`,
      {
        method: "PATCH",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(notificationId),
      }
    );
    navigate(url);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "90px",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "400px",
      }}
    >
      {error ? (
        error
      ) : notifications.length ? (
        notifications.map((notification) => {
          console.log(notification);
          return (
            <div
              style={{
                height: "90px",
                width: "100%",
                border: "solid red",
                display: "flex",
                backgroundColor: notification.viewed ? "#afe0de" : "#2c3e50",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 1119,
              }}
              onClick={() => {
                console.log("AWDWAD");
                notificationClicked(
                  notification.url,
                  notification.notification_id
                );
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                  backgroundColor: notification.viewed ? "green" : "red",
                }}
              ></div>

              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                {new Date(notification.created_at).toLocaleDateString()}
              </div>
              <div>{notification.message}</div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            height: "90px",
            width: "100%",
            border: "solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#2c3e50",
          }}
        >
          No Notifications!
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
