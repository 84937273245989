import React, { useEffect, useState } from "react";
import Navbar from "scenes/navbar";
import styles from "./MenageAlbum.module.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AlbumThumbnail from "./blank-profile-picture.webp";
import MediumImage from "components/mediumImage";
import AddSinglePicture from "components/addSinglePicture";
import CurrentPageInfo from "components/CurrentPageInfo";

const MenageAlbum = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState();
  const { albumId } = useParams();
  const [albumInfo, setAlbumInfo] = useState([]);
  const [imageUpload, setImageUpload] = useState(false);

  const fetchAlbumInformations = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/${albumId}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAlbumInfo(await res.json());
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    fetchAlbumInformations();
  }, []);

  return (
    <>
      <Navbar />
      <CurrentPageInfo pageName="Menage Album" />
      {console.log(albumInfo)}
      {imageUpload && (
        <AddSinglePicture
          type="albumThumbnail"
          setIsOpen={setImageUpload}
          albumId={albumId}
        />
      )}
      <div className={styles["album-page-container"]}>
        <div className={styles["image-and-buttons-container"]}>
          <div
            className={styles["image-container"]}
            onClick={() => setImageUpload(true)}
          >
            {albumInfo["album_thumbnail"] ? (
              <MediumImage filename={albumInfo["album_thumbnail"]} />
            ) : (
              <img src={AlbumThumbnail} alt="Album Thumbnail" />
            )}
          </div>
          <div className={styles["buttons-container"]}>
            <button
              onClick={() =>
                navigate(`/friends/?chooseAdmin=true&albumId=${albumId}`)
              }
            >
              Add Administrator
            </button>
            <button
              onClick={() =>
                navigate(`/friends/?chooseViewer=true&albumId=${albumId}`)
              }
            >
              Add viewer
            </button>
          </div>
        </div>
        <div className={styles["informations-container"]}>
          <table className={styles["info-table"]}>
            <tr>
              <td>Album name: </td>
              <td>{albumInfo["name"]}</td>
            </tr>
            <tr>
              <td>Owner: </td>
              <td>{albumInfo["owner"]}</td>
            </tr>
            <tr>
              <td>Photo count: </td>
              <td>{albumInfo["photos"]?.length || 0}</td>
            </tr>
            <tr>
              <td>Administrators: </td>
              <td>
                {albumInfo["admin_usernames"]?.map((username, index) => {
                  return (
                    <span>
                      <a
                        href={`/userPage/${albumInfo["administrators"][index]}`}
                      >
                        {username}
                      </a>
                    </span>
                  );
                })}
              </td>
            </tr>
            <tr>
              <td>Viewers: </td>
              <td>
                {albumInfo["viewer_usernames"]?.map((username, index) => {
                  return (
                    <span>
                      <a href={`/userPage/${albumInfo["viewers"][index]}`}>
                        username
                      </a>
                    </span>
                  );
                })}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default MenageAlbum;
