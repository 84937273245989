// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__QwD\\+f {
  background-color: #ffcc80; /* Light Orange */
  color: #333; /* Dark Gray */
  padding: 15px 0;
  height: 120px; /* Increased fixed height */
  overflow: hidden; /* Hide overflow content */
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Footer_footerContent__edf9I {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.Footer_footerSection__kvdy9 {
  flex: 1 1;
  margin-right: 10px;
}

.Footer_footerHeading__mpgcJ {
  font-size: 16px;
  margin-bottom: 8px;
  color: #333; /* Dark Gray */
}

.Footer_footerText__HLuh1 {
  font-size: 14px;
  color: #666; /* Gray */
  max-height: 60px; /* Limit height for text content */
  overflow: hidden; /* Hide overflow text */
}

.Footer_footerList__kPw53 {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Footer_footerListItem__thtnR {
  margin-bottom: 4px;
}

.Footer_footerBottom__SA6TJ {
  border-top: 1px solid #ddd; /* Light Gray */
  margin-top: 15px;
  padding-top: 8px;
  text-align: center;
  color: #666; /* Gray */
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,iBAAiB;EAC5C,WAAW,EAAE,cAAc;EAC3B,eAAe;EACf,aAAa,EAAE,2BAA2B;EAC1C,gBAAgB,EAAE,0BAA0B;EAC5C,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW,EAAE,cAAc;AAC7B;;AAEA;EACE,eAAe;EACf,WAAW,EAAE,SAAS;EACtB,gBAAgB,EAAE,kCAAkC;EACpD,gBAAgB,EAAE,uBAAuB;AAC3C;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B,EAAE,eAAe;EAC3C,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW,EAAE,SAAS;AACxB","sourcesContent":[".footer {\n  background-color: #ffcc80; /* Light Orange */\n  color: #333; /* Dark Gray */\n  padding: 15px 0;\n  height: 120px; /* Increased fixed height */\n  overflow: hidden; /* Hide overflow content */\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n\n.footerContent {\n  display: flex;\n  justify-content: space-between;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n\n.footerSection {\n  flex: 1;\n  margin-right: 10px;\n}\n\n.footerHeading {\n  font-size: 16px;\n  margin-bottom: 8px;\n  color: #333; /* Dark Gray */\n}\n\n.footerText {\n  font-size: 14px;\n  color: #666; /* Gray */\n  max-height: 60px; /* Limit height for text content */\n  overflow: hidden; /* Hide overflow text */\n}\n\n.footerList {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.footerListItem {\n  margin-bottom: 4px;\n}\n\n.footerBottom {\n  border-top: 1px solid #ddd; /* Light Gray */\n  margin-top: 15px;\n  padding-top: 8px;\n  text-align: center;\n  color: #666; /* Gray */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__QwD+f`,
	"footerContent": `Footer_footerContent__edf9I`,
	"footerSection": `Footer_footerSection__kvdy9`,
	"footerHeading": `Footer_footerHeading__mpgcJ`,
	"footerText": `Footer_footerText__HLuh1`,
	"footerList": `Footer_footerList__kPw53`,
	"footerListItem": `Footer_footerListItem__thtnR`,
	"footerBottom": `Footer_footerBottom__SA6TJ`
};
export default ___CSS_LOADER_EXPORT___;
