// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonItem_person-container__16LRy {
  height: 50px;
  min-width: 450px;
  padding: 15px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 10px;
}

.PersonItem_person-image__SftCz {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.PersonItem_name-container__Okz2O {
  font-size: 18px;
}

.PersonItem_dropdown-dots__Bnc-x {
  height: 30px;
  cursor: pointer;
}

.PersonItem_unknown-name__\\+Cl61 {
  color: #858585a6;
}

.PersonItem_person-name__J\\+Gu7 {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/personItem/PersonItem.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".person-container {\n  height: 50px;\n  min-width: 450px;\n  padding: 15px;\n  display: flex;\n  align-items: center;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  cursor: pointer;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\n.person-image {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  object-fit: cover;\n}\n\n.name-container {\n  font-size: 18px;\n}\n\n.dropdown-dots {\n  height: 30px;\n  cursor: pointer;\n}\n\n.unknown-name {\n  color: #858585a6;\n}\n\n.person-name {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"person-container": `PersonItem_person-container__16LRy`,
	"person-image": `PersonItem_person-image__SftCz`,
	"name-container": `PersonItem_name-container__Okz2O`,
	"dropdown-dots": `PersonItem_dropdown-dots__Bnc-x`,
	"unknown-name": `PersonItem_unknown-name__+Cl61`,
	"person-name": `PersonItem_person-name__J+Gu7`
};
export default ___CSS_LOADER_EXPORT___;
