// import React, { useEffect, useState } from "react";
// import { Button, Card, CardContent, Grid } from "@mui/material";
// import { Form, Formik } from "formik";
// import { array, object, string } from "yup";
// import MultipleFileUpload from "./upload/MultipleFileUploadField";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";

import React from "react";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Error from "components/Error";

const AddPhotos = ({ status, setStatus }) => {
  const token = useSelector((state) => state.token);
  const family = useSelector((state) => state.currentFamily);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file) => ({ file, errors: [] }));
    setFiles((curr) => [...curr, ...mappedAcc, ...rejFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const formSubmit = async () => {
    try {
      files.map(async (fileWrapper) => {
        const data = new FormData();
        data.append("file", fileWrapper.file);
        data.append("name", fileWrapper.file.name);
        await axios
          .post(
            `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photos`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                cors: true,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .catch((e) => {
            console.log(e.response);
          });
      });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={formSubmit}>
      {({ values, errors }) => (
        <Form>
          {files.length ? (
            <>
              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  width: "1200px",
                  height: "800px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    width: "100%",
                    height: "90%",
                    margin: "40px",
                    backgroundColor: "#f5eded",
                    padding: "30px",
                  }}
                >
                  <input {...getInputProps()} />
                  {files.map((fileWrapper, index) => {
                    return (
                      <div
                        style={{
                          height: "60px",
                          width: "160px",
                          backgroundColor: "#a9d8b8",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {fileWrapper["file"]["path"]}
                        <div
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            position: "absolute",
                            right: "0",
                            top: "0",
                            fontSize: "15px",
                            backgroundColor: "red",
                            height: "15px",
                            width: "15px",
                          }}
                          onClick={() => files.splice(index, 1)}
                        >
                          X
                        </div>
                      </div>
                    );
                  })}

                  {/* {JSON.stringify({ values, errors }, null, 4)} */}
                </div>
              </div>
              <div
                style={{
                  width: "1200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <button
                  style={{
                    height: "40px",
                    width: "150px",
                    backgroundColor: "orange",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  Submit
                </button>
                {status && (
                  <button
                    style={{
                      height: "40px",
                      width: "150px",
                      backgroundColor: "orange",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    Skip for now
                  </button>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  width: "1200px",
                  height: "800px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "90%",
                    margin: "40px",
                    backgroundColor: "#f5eded",
                  }}
                >
                  <input {...getInputProps()} />
                  <p style={{ fontSize: "20px", color: "#282d2e" }}>
                    Drag 'n' drop some files here, or click to select files
                  </p>

                  {/* {JSON.stringify({ values, errors }, null, 4)} */}
                </div>
              </div>
              <div
                style={{
                  width: "1200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <button
                  style={{
                    height: "40px",
                    width: "150px",
                    backgroundColor: "orange",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  Submit
                </button>
                {status && (
                  <button
                    style={{
                      height: "40px",
                      width: "150px",
                      backgroundColor: "orange",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    Skip for now
                  </button>
                )}
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddPhotos;
