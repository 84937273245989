import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styles from './RegisterPage.module.css'

const DropzoneComponent = ({ setProfilePicture }) => {
  const [file, setFile] = useState([])
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    console.log(acceptedFiles[0], 'acc')
    console.log(rejectedFiles, 'rej')
    setFile(acceptedFiles[0])
    setProfilePicture(acceptedFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
      'image/png': ['.png'],
      'image/tiff': ['.tif', '.tiff'],
    },
    maxSize: 1024 * 1024,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={styles['dropzone-box']}>
        {file?.name ? (
          <div>
            <p>{file.name}</p>
          </div>
        ) : isDragActive ? (
          <p>Drag and drop file here, or click to select files</p>
        ) : (
          <div style={{ display: 'flex' }}>
            <p>Drop your file here</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default DropzoneComponent
