import React from "react";
import styles from "./CurrentPageInfo.module.css";
import Error from "components/Error";

const CurrentPageInfo = ({ pageName, error, setError }) => {
  return (
    <>
      <div className={styles["CurrentPageInfo"]}>
        <p>{pageName}</p>
      </div>
      {error && <Error message={error} closingFunction={setError} />}
    </>
  );
};

export default CurrentPageInfo;
