import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import CreateFamily from "scenes/createFamily";
import Gallery from "scenes/gallery";
import PhotoPage from "scenes/PhotoPage";
import RegisterPage from "scenes/registerPage";
import PhotoGeoSearch from "scenes/photoGeoSearch";
import UserSearch from "scenes/userSearch";
import UserPage from "scenes/userPage";
import Albums from "scenes/albums";
import CreateAlbum from "scenes/createAlbum";
import Album from "scenes/album";
import Friends from "scenes/friends";
import PhotoSearch from "scenes/photoSearch";
import ChooseSubscription from "scenes/chooseSubscription";
import PaymentSite from "scenes/paymentSite";
import SearchFaces from "scenes/searchFaces";
import SavedPhotos from "scenes/savedPhotos";
import Chat from "scenes/chat";
import PersonPage from "scenes/personPage";
import Story from "scenes/story";
import CreatePerson from "scenes/createPerson";
import PeoplePage from "scenes/peoplePage";
import PeopleSearch from "scenes/peopleSearch";
import AddSinglePicture from "components/addSinglePicture";
import About from "scenes/about";
import Faq from "scenes/faq";
import MenageAlbum from "scenes/menageAlbum";
import IsIdEqualToUserWrapper from "wrapperRoutes/isIdEqualToUser";
import SettingsPage from "scenes/settings";
import EmailVerification from "scenes/verification";
import PasswordReset from "scenes/passwordReset";

function App() {
  // const isAuth = Boolean(useSelector((state) => state.token));
  const isAuth = true;

  return (
    <div className="app">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="/"
            element={isAuth ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route
            path="home"
            element={isAuth ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route
            path="photoGallery"
            element={true || isAuth ? <Gallery /> : <Navigate to="/login" />}
          />
          <Route
            path="album/:albumId"
            element={isAuth ? <Album /> : <Navigate to="/login" />}
          />
          <Route
            path="familyCreation"
            element={isAuth ? <CreateFamily /> : <Navigate to="/login" />}
          />
          <Route
            path="profilePage"
            element={isAuth ? <ProfilePage /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="photoPage/:photoId/?:faceId?"
            element={isAuth ? <PhotoPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/photoGeoSearch"
            element={isAuth ? <PhotoGeoSearch /> : <Navigate to="login" />}
          />
          <Route
            path="/userSearch"
            element={isAuth ? <UserSearch /> : <Navigate to="login" />}
          />
          input
          <Route
            path="/userPage/:userId"
            element={
              isAuth ? <IsIdEqualToUserWrapper /> : <Navigate to="login" />
            }
          />
          <Route
            path="/albums"
            element={isAuth ? <Albums /> : <Navigate to="login" />}
          />
          <Route
            path="/createAlbum"
            element={isAuth ? <CreateAlbum /> : <Navigate to="login" />}
          />
          <Route
            path="/friends"
            element={isAuth ? <Friends /> : <Navigate to="login" />}
          />
          <Route
            path="/photoSearch"
            element={isAuth ? <PhotoSearch /> : <Navigate to="login" />}
          />
          <Route
            path="/chooseSubscription"
            element={isAuth ? <ChooseSubscription /> : <Navigate to="login" />}
          />
          <Route
            path="/payment/:planType"
            element={isAuth ? <PaymentSite /> : <Navigate to="login" />}
          />
          <Route
            path="/searchFaces"
            element={isAuth ? <SearchFaces /> : <Navigate to="login" />}
          />
          <Route
            path="/savedPhotos"
            element={isAuth ? <SavedPhotos /> : <Navigate to="login" />}
          />
          <Route
            path="/chat/:receiver"
            element={isAuth ? <Chat /> : <Navigate to="login" />}
          />
          <Route
            path="/personPage/:personId"
            element={isAuth ? <PersonPage /> : <Navigate to="login" />}
          />
          <Route
            path="/story/:storyId"
            element={isAuth ? <Story /> : <Navigate to="login" />}
          />
          <Route
            path="/createNewPerson/?:faceId?"
            element={isAuth ? <CreatePerson /> : <Navigate to="login" />}
          />
          <Route
            path="/peoplePage"
            element={isAuth ? <PeoplePage /> : <Navigate to="login" />}
          />
          <Route
            path="/peopleSearch"
            element={isAuth ? <PeopleSearch /> : <Navigate to="login" />}
          />
          <Route
            path="/add"
            element={isAuth ? <AddSinglePicture /> : <Navigate to="login" />}
          />
          <Route
            path="/menageAlbum/:albumId"
            element={isAuth ? <MenageAlbum /> : <Navigate to="login" />}
          />
          <Route
            path="/settings"
            element={isAuth ? <SettingsPage /> : <Navigate to="login" />}
          />
          <Route path="/verification" element={<EmailVerification />} />
          <Route path="/password-reset" element={<PasswordReset />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
