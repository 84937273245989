import React from "react";
import FooterStyles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={FooterStyles.footer}>
      <div className={FooterStyles.footerContent}>
        <div className={FooterStyles.footerSection}>
          <h4 className={FooterStyles.footerHeading}>About Us</h4>
          <p className={FooterStyles.footerText}>
            Learn more about our family photos storage platform and our mission.
          </p>
        </div>

        <div className={FooterStyles.footerSection}>
          <h4 className={FooterStyles.footerHeading}>Quick Links</h4>
          <ul className={FooterStyles.footerList}>
            <li className={FooterStyles.footerListItem}>
              <a href="/">Home</a>
            </li>
            <li className={FooterStyles.footerListItem}>
              <a href="/albums">Albums</a>
            </li>
            <li className={FooterStyles.footerListItem}>
              <a href="/upload">Upload</a>
            </li>
            <li className={FooterStyles.footerListItem}>
              <a href="/faq">FAQ</a>
            </li>
          </ul>
        </div>

        <div className={FooterStyles.footerSection}>
          <h4 className={FooterStyles.footerHeading}>Contact Us</h4>
          <p className={FooterStyles.footerText}>
            Reach out to our support team for any assistance or inquiries.
          </p>
        </div>
      </div>
      <div className={FooterStyles.footerBottom}>
        <p className={FooterStyles.footerText}>
          © 2024 FamilyPhotos.com. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
