import React, { useEffect, useState } from "react";
import { ReactComponent as Hamburger } from "./hamburger-menu-svgrepo-com.svg";
import { ReactComponent as Close } from "./close-x-svgrepo-com.svg";
import { ReactComponent as ArrowDown } from "./down-arrow-svgrepo-com.svg";
import profilePicture from "./blank-profile-picture.webp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentFamily, setLogout } from "state";

const NavbarMobile = () => {
  const navigate = useNavigate();
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const [galleryDopdown, setGalleryDropdown] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [currentFamilies, setCurrentFamilies] = useState([]);
  const username = useSelector((state) => state.username);
  const family = useSelector((state) => state.family);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  return (
    <div className="mobile-navbar-container">
      <div className="hamburger-container">
        {isHamburgerActive ? (
          <div className="menu-container">
            <div className="hamburger-menu">
              <div className="close-icon-container">
                <Close
                  className="close-icon"
                  onClick={() => setIsHamburgerActive(false)}
                />
              </div>
              <div className="options-container">
                <div
                  className="hamburger-menu-profile"
                  onClick={() => navigate("/profilePage")}
                >
                  <img src={profilePicture} alt="Profile Picture" />
                  <p>{username}</p>
                </div>
                <div className="family-info">
                  {/* <select
                  style={{
                    border: "none",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  defaultValue={family}
                  onChange={(e) => {
                    dispatch(setCurrentFamily(e.target.value));
                    window.location.reload(true);
                  }}
                >
                  {currentFamilies?.map((familyWrapper, index) => {
                    console.log(familyWrapper["value"]);
                    return (
                      <option
                        key={index}
                        // selected={familyWrapper["value"] === family}
                        value={familyWrapper["value"]}
                        style={{
                          height: "35px",
                        }}
                      >
                        {familyWrapper["label"]}
                      </option>
                    );
                  }) || (
                    <option selected value="None">
                      No families Yet!
                    </option>
                  )}
                </select> */}
                </div>
                <div
                  className="hamburger-menu-item"
                  onClick={() => {
                    setGalleryDropdown((curr) => !curr);
                    setSearchDropdown(false);
                  }}
                >
                  <div>
                    <p>Gallery</p>
                    <ArrowDown
                      className={
                        "option-dropdown arrow-down " +
                        (galleryDopdown && "upside-down")
                      }
                    />
                  </div>
                </div>
                {galleryDopdown && (
                  <div className="dropdown">
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/photoGallery")}
                    >
                      View Photos
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/albums")}
                    >
                      Albums
                    </div>
                  </div>
                )}
                <div
                  className="hamburger-menu-item"
                  onClick={() => {
                    setSearchDropdown((curr) => !curr);
                    setGalleryDropdown(false);
                  }}
                >
                  <p>Search</p>
                  <ArrowDown
                    className={
                      "option-dropdown arrow-down " +
                      (searchDropdown && "upside-down")
                    }
                  />
                </div>
                {searchDropdown && (
                  <div className="dropdown">
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/searchFaces")}
                    >
                      Search Faces
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/photoGeoSearch")}
                    >
                      GeoSearch Photos
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/photoSearch")}
                    >
                      Search Photos
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/userSearch")}
                    >
                      Search Users
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/peopleSearch")}
                    >
                      Search People
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="logout"
              onClick={() => {
                dispatch(setLogout());
                navigate("/login");
              }}
            >
              Log Out
            </div>
          </div>
        ) : (
          <Hamburger
            className="hamburger"
            onClick={() => setIsHamburgerActive(true)}
          />
        )}
      </div>
      <div
        className="logo-center"
        onClick={() => {
          navigate("/home");
        }}
      >
        FamilyPhotos
      </div>
    </div>
  );
};

export default NavbarMobile;
