// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Faq_faq-container__omQkc {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Faq_faq-container__omQkc h1 {
  color: #4caf50;
  text-align: center;
}

.Faq_faq-list__YYm6w {
  margin-top: 20px;
}

.Faq_faq-item__ue1XW {
  margin-bottom: 20px;
}

.Faq_faq-item__ue1XW h3 {
  color: #333;
  margin-bottom: 10px;
}

.Faq_faq-item__ue1XW p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/faq/Faq.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,sBAAsB;EACtB,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".faq-container {\n  max-width: 800px;\n  margin: auto;\n  padding: 20px;\n  font-family: \"Arial\", sans-serif;\n  background-color: #fff;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n}\n\n.faq-container h1 {\n  color: #4caf50;\n  text-align: center;\n}\n\n.faq-list {\n  margin-top: 20px;\n}\n\n.faq-item {\n  margin-bottom: 20px;\n}\n\n.faq-item h3 {\n  color: #333;\n  margin-bottom: 10px;\n}\n\n.faq-item p {\n  color: #666;\n  font-size: 16px;\n  line-height: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq-container": `Faq_faq-container__omQkc`,
	"faq-list": `Faq_faq-list__YYm6w`,
	"faq-item": `Faq_faq-item__ue1XW`
};
export default ___CSS_LOADER_EXPORT___;
