// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_sign-up-text__Af1wq {
  text-decoration: underline;
  cursor: pointer;
  color: grey;
}

.LoginPage_sign-up-text__Af1wq:hover {
  color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/loginPage/LoginPage.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".sign-up-text {\n  text-decoration: underline;\n  cursor: pointer;\n  color: grey;\n}\n\n.sign-up-text:hover {\n  color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sign-up-text": `LoginPage_sign-up-text__Af1wq`
};
export default ___CSS_LOADER_EXPORT___;
