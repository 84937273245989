// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenageAlbum_album-page-container__SIvVC {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: row;
}

.MenageAlbum_image-and-buttons-container__w-7\\+J {
  display: flex;
  flex-direction: column;
  width: max(350px, 50%);
  padding: 50px;
}

.MenageAlbum_image-container__55fhX {
  width: 350px;
  min-height: 350px;
}

.MenageAlbum_image-container__55fhX img {
  width: 100%;
  height: auto;
  display: block;
}

.MenageAlbum_buttons-container__vkN2v {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.MenageAlbum_informations-container__IOhxs {
  display: flex;
  height: 100%;
  width: 100%;
}

.MenageAlbum_info-table__FK5PV {
  height: 100%;
  width: 100%;
  border-spacing: 100px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/menageAlbum/MenageAlbum.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".album-page-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  flex-direction: row;\n}\n\n.image-and-buttons-container {\n  display: flex;\n  flex-direction: column;\n  width: max(350px, 50%);\n  padding: 50px;\n}\n\n.image-container {\n  width: 350px;\n  min-height: 350px;\n}\n\n.image-container img {\n  width: 100%;\n  height: auto;\n  display: block;\n}\n\n.buttons-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.informations-container {\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\n.info-table {\n  height: 100%;\n  width: 100%;\n  border-spacing: 100px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"album-page-container": `MenageAlbum_album-page-container__SIvVC`,
	"image-and-buttons-container": `MenageAlbum_image-and-buttons-container__w-7+J`,
	"image-container": `MenageAlbum_image-container__55fhX`,
	"buttons-container": `MenageAlbum_buttons-container__vkN2v`,
	"informations-container": `MenageAlbum_informations-container__IOhxs`,
	"info-table": `MenageAlbum_info-table__FK5PV`
};
export default ___CSS_LOADER_EXPORT___;
