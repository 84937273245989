import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./MediumImage.module.css";
import BlankProfilePicture from "./blank-profile-picture.webp";

const MediumImage = ({ filename, url = null }) => {
  const token = useSelector((state) => state.token);
  const [image, setImage] = useState("");
  const fetchImage = async () => {
    fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/thumbnail/${filename}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        setImage(URL.createObjectURL(blob));
      });
  };

  useEffect(() => {
    if (url !== null) {
      console.log("url");
      console.log(url);
      setImage(url);
    } else {
      console.log("ewq11");
      fetchImage();
    }
  }, [url, filename]);

  return (
    <div className={styles["box"]}>
      <div className={styles["shadow"]}></div>
      {console.log(process.env.PUBLIC_URL, "public")}
      <img src={image || BlankProfilePicture} alt="image" />
    </div>
  );
};

export default MediumImage;
