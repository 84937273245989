// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
}

.customSlider {
  /* max width of your slider */

  max-width: 300px;
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 5px;
  /* thickness of the track */
  height: 10px;
  /* default color of your track */
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--main-color);
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.search-button {
  border: none;
  width: 90px;
  height: 45px;
  background-color: #90a0a3;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/photoGeoSearch/index.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;;EAE7B,gBAAgB;EAChB,iEAAiE;EACjE,YAAY;AACd;;AAEA;EACE,8DAA8D;EAC9D,QAAQ;EACR,2BAA2B;EAC3B,YAAY;EACZ,gCAAgC;EAChC,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;EACxC,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,6BAA6B;EAC7B,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[":root {\n  --main-color: rgb(104, 117, 217);\n  --main-color-hover: rgb(104, 117, 217, 0.2);\n  --track-color: #ddd;\n}\n\n.customSlider {\n  /* max width of your slider */\n\n  max-width: 300px;\n  /* Optional: Only If you want to center your slider in the page */\n  margin: auto;\n}\n\n.customSlider-track {\n  /* Top value to align your track to the center of your thumb */\n  top: 5px;\n  /* thickness of the track */\n  height: 10px;\n  /* default color of your track */\n  background: var(--track-color);\n}\n\n.customSlider-track.customSlider-track-0 {\n  /* color of the track before the thumb */\n  background: var(--main-color);\n}\n\n.customSlider-thumb {\n  cursor: pointer;\n  /*color for the thumb */\n  background: var(--main-color);\n  /* shape of the thumb: circle */\n  width: 20px;\n  height: 20px;\n  border-radius: 100%;\n  /* remove default outline when selected */\n  outline: none;\n}\n\n.customSlider-thumb:hover {\n  box-shadow: 0 0 0 8px var(--main-color-hover);\n}\n\n.search-button {\n  border: none;\n  width: 90px;\n  height: 45px;\n  background-color: #90a0a3;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
