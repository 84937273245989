import React, { useState } from "react";
import AddInformations from "./addInformations";
import AddPhotos from "./addPhotos";

const CreateFamily = () => {
  const [status, setStatus] = useState("informations");

  const changeStatus = () => {
    if (status === "informations") {
      setStatus("photos");
    } else {
      setStatus("informations");
    }
  };
  // return <AddPhotos />;
  if (status === "informations") {
    return <AddInformations status={status} setStatus={changeStatus} />;
  } else {
    return <AddPhotos status={status} setStatus={changeStatus} />;
  }
};

export default CreateFamily;
