import React from "react";
import { ReactComponent as UploadIcon } from "../icons/upload-sign-svgrepo-com.svg";

const UploadButton = ({ onClick }) => {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "165px",
        height: "50px",
        borderRadius: "10px",
        border: "1px solid black",
        cursor: "pointer",
        backgroundColor: "#F9E00E",
      }}
      onClick={() => onClick(true)}
    >
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          fontSize: "15px",
        }}
      >
        Upload photos
        <span>
          <UploadIcon />
        </span>
      </p>
    </button>
  );
};

export default UploadButton;
