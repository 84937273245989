import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import "./index.css";
import { useNavigate } from "react-router-dom";
import PersonItem from "components/personItem";
import _ from "lodash";
import PersonItemSekeleton from "skeletons/personItemSkeleton";
import CurrentPageInfo from "components/CurrentPageInfo";
import NoResults from "components/NoResults";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-large-svgrepo-com.svg";

const PeoplePage = () => {
  const PLACEHOLDER_COUNT = 8;
  const LIMIT = 15;
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const [peopleOffset, setPeopleOffset] = useState(0);
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const observer = useRef();
  const lastBookElementRef = useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPeopleOffset((curr) => curr + LIMIT);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const fetchPeople = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/${user_id}/people/?offset=${peopleOffset}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status !== 200) {
        setLoading(false);
        return;
      }
      const people = (await res.json())["data"];
      setLoading(false);
      setPeople((curr) => [...curr, ...people]);
    } catch ({ name, message }) {
      setLoading(false);
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    fetchPeople();
  }, []);

  useEffect(() => {
    if (peopleOffset !== 0) {
      fetchPeople();
    }
  }, [peopleOffset]);

  return (
    <>
      <Navbar />
      <CurrentPageInfo pageName="People" error={error} setError={setError} />
      <div className="people-page-container">
        {people.length !== 0 ? (
          <>
            <div
              style={{
                width: "450px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "15px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/createNewPerson/")}
            >
              <PlusIcon
                style={{
                  width: "40px",
                  height: "40px",
                  fill: "#ddd",
                }}
              />
            </div>
            {people.map((person, index) => {
              console.log(person);
              if (index + 1 === peopleOffset + LIMIT) {
                return (
                  <div ref={lastBookElementRef}>
                    <PersonItem person={person} index={index} />
                  </div>
                );
              } else {
                return (
                  <React.Fragment>
                    <PersonItem person={person} index={index} />
                  </React.Fragment>
                );
              }
            })}
          </>
        ) : (
          <>
            <div
              style={{
                width: "450px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "15px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/createNewPerson/")}
            >
              <PlusIcon
                style={{
                  width: "40px",
                  height: "40px",
                  fill: "#ddd",
                }}
              />
            </div>
            <NoResults
              primaryText="You didn't add any people yet."
              secondaryText="You can to this by clicking a button above"
            />
          </>
        )}
        {loading &&
          _.range(PLACEHOLDER_COUNT).map((index) => {
            return <PersonItemSekeleton key={index} />;
          })}
      </div>
    </>
  );
};

export default PeoplePage;
