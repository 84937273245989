import React from "react";

const EmptyListInfo = ({ text, secondaryText, aTagUrl, aTagText }) => {
  return (
    <div>
      <p>{text}</p>
      <p>
        {secondaryText} <a href={aTagUrl}>{aTagText}</a>
      </p>
    </div>
  );
};

export default EmptyListInfo;
