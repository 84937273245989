import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GalleryItemStyles from "./GalleryItem.module.css";
import GalleryItemSkeleton from "skeletons/galleryItemSkeleton";

const GalleryItem = ({
  imageFilename,
  setIsLastImageLoaded,
  setChecked = null,
  checked = [],
  checkBox = false,
}) => {
  const token = useSelector((state) => state.token);
  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const isChecked = checked.indexOf(imageFilename) > -1;
  const handleCheck = () => {
    if (isChecked) {
      setChecked((curr) => {
        curr.splice(curr.indexOf(imageFilename), 1);
        return [...curr];
      });
    } else {
      setChecked((curr) => [...curr, imageFilename]);
    }
  };

  const fetchImage = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/thumbnail/${imageFilename}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setIsLoading(false);
          setImage(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (imageFilename) {
      console.log("galleryitem run");
      fetchImage();
    }
  }, []);

  if (isLoading === true) {
    return <GalleryItemSkeleton />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          height: "200px",
          minWidth: "150px",
          width: "auto",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <img
          src={image}
          alt="image"
          onLoad={() => setIsLastImageLoaded && setIsLastImageLoaded(true)}
        />
      </div>
    );
  }
};

export default GalleryItem;
