import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogin } from 'state';
import Error from 'components/Error';
import { Button, message } from 'antd';
import { Box, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

const registerSchema = yup.object().shape({
  email: yup.string().email('Invalid Email').required('required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters long')
    .max(40, 'Password can not be longer than 40 characters')
    .required('required'),
});

const initialValuesLogin = {
  email: '',
  password: '',
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const login = async ({ email, password }) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/login`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const returnedData = await res.json();
      if (res.status !== 200) {
        message.error(returnedData?.detail || 'Failed to Login!');
      }

      if (returnedData['token']) {
        dispatch(
          setLogin({
            user: returnedData['user_id'],
            token: returnedData['token'],
            username: returnedData['username'],
          })
        );
        navigate('/home');
      }
    } catch ({ name, message }) {
      console.log('failed to login');
      message.success('Failed to login!');
    }
  };

  return (
    <div className="w-full h-[450px] bg-white max-w-[500px] rounded-[20px] p-[40px] flex items-center justify-start flex-col">
      {error && <Error message={error} closingFunction={setError} />}
      <h2 className="font-[Poppins] text-2xl font-bold pt-2">
        Welcome to Family Photos!!!
      </h2>
      <div className="w-full pt-[40px]">
        <Formik
          onSubmit={login}
          validationSchema={registerSchema}
          initialValues={initialValuesLogin}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: 'grid',
                  gap: '30px',
                  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: '500px',
                }}
              >
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 4', width: '100%' }}
                />
                <TextField
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: 'span 4', width: '100%' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    gridColumn: 'span 4',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                      padding: '1rem',
                      marginBottom: '1rem',
                      height: '56px',
                    }}
                  >
                    LOGIN
                  </Button>
                  <div>
                    Don't have an account?{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        navigate('/register');
                        resetForm();
                      }}
                    >
                      Sign up here.
                    </span>
                  </div>
                  {/* <Typography
                   
                    sx={{
                      textDecoration: 'underline',
                      color: 'grey',
                      '&:hover': {
                        cursor: 'pointer',
                        color: 'blue',
                      },
                    }}
                  >
                    Already have an account? Log in here.
                  </Typography> */}
                </Box>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {/* 
      <div className={styles["login-form-container"]}>
        <input
          className={styles["inputs"]}
          placeholder="E-mail"
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={styles["inputs"]}
          placeholder="Password"
          type="text"
          name="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className={styles["submit-button"]}
          type="button"
          onClick={() => handleFormSubmit()}
        >
          Log in
        </button>
      </div> */}
    </div>
  );
};

export default LoginForm;
