// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions-card {
  width: 350px;
  height: 70vh;
  background-color: #d9fffe;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.card-item {
  height: 50px;
}
.card-item button {
  border: none;
  height: 40px;
  width: 140px;
  cursor: pointer;
}

.user-page-container {
  display: flex;
  justify-content: center;
  width: auto;
  padding-top: 40px;
  gap: 25px;
}

@media screen and (max-width: 1000px) {
  .user-page-container {
    align-items: center;
    flex-direction: column;
  }
  .user-page-container :nth-child(1) {
    order: 1;
  }
  .user-page-container :nth-child(2) {
    order: 3;
  }
  .user-page-container :nth-child(3) {
    order: 2;
    background-color: transparent;
    width: auto;
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/userPage/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE;IACE,mBAAmB;IACnB,sBAAsB;EACxB;EACA;IACE,QAAQ;EACV;EACA;IACE,QAAQ;EACV;EACA;IACE,QAAQ;IACR,6BAA6B;IAC7B,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".actions-card {\n  width: 350px;\n  height: 70vh;\n  background-color: #d9fffe;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-sizing: border-box;\n  padding: 10px;\n}\n\n.card-item {\n  height: 50px;\n}\n.card-item button {\n  border: none;\n  height: 40px;\n  width: 140px;\n  cursor: pointer;\n}\n\n.user-page-container {\n  display: flex;\n  justify-content: center;\n  width: auto;\n  padding-top: 40px;\n  gap: 25px;\n}\n\n@media screen and (max-width: 1000px) {\n  .user-page-container {\n    align-items: center;\n    flex-direction: column;\n  }\n  .user-page-container :nth-child(1) {\n    order: 1;\n  }\n  .user-page-container :nth-child(2) {\n    order: 3;\n  }\n  .user-page-container :nth-child(3) {\n    order: 2;\n    background-color: transparent;\n    width: auto;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
