// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeopleSearch_people-search-page-container__wX4W7 {
  margin-top: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PeopleSearch_input-container__uokCb {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.PeopleSearch_user-input__aOFwC {
  padding: 5px;
  font-size: 16px;
  border-width: 1.5px;
  border-color: #1f2324;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 7px;
  box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);
  text-shadow: 0px 0px 0px rgba(66, 66, 66, 0);
  margin-right: 10px;
}

.PeopleSearch_search-button__dl9gj {
  font-family: Roboto, sans-serif;
  font-weight: 0;
  font-size: 14px;
  color: #fff;
  background-color: #0066cc;
  padding: 10px 30px;
  border: 2px solid #0066cc;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 50px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.PeopleSearch_search-button__dl9gj:hover {
  transition: 1000ms;
  padding: 10px 31px;
  transform: translateY(-0px);
  background-color: #fff;
  color: #0066cc;
  border: solid 2px #0066cc;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/peopleSearch/PeopleSearch.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,6CAA6C;EAC7C,4CAA4C;EAC5C,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,cAAc;EACd,eAAe;EACf,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,wCAAwC;EACxC,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,2BAA2B;EAC3B,sBAAsB;EACtB,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":[".people-search-page-container {\n  margin-top: 30px;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.input-container {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 20px;\n}\n\n.user-input {\n  padding: 5px;\n  font-size: 16px;\n  border-width: 1.5px;\n  border-color: #1f2324;\n  background-color: #ffffff;\n  color: #000000;\n  border-style: solid;\n  border-radius: 7px;\n  box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);\n  text-shadow: 0px 0px 0px rgba(66, 66, 66, 0);\n  margin-right: 10px;\n}\n\n.search-button {\n  font-family: Roboto, sans-serif;\n  font-weight: 0;\n  font-size: 14px;\n  color: #fff;\n  background-color: #0066cc;\n  padding: 10px 30px;\n  border: 2px solid #0066cc;\n  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;\n  border-radius: 50px;\n  transition: 1000ms;\n  transform: translateY(0);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n}\n\n.search-button:hover {\n  transition: 1000ms;\n  padding: 10px 31px;\n  transform: translateY(-0px);\n  background-color: #fff;\n  color: #0066cc;\n  border: solid 2px #0066cc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"people-search-page-container": `PeopleSearch_people-search-page-container__wX4W7`,
	"input-container": `PeopleSearch_input-container__uokCb`,
	"user-input": `PeopleSearch_user-input__aOFwC`,
	"search-button": `PeopleSearch_search-button__dl9gj`
};
export default ___CSS_LOADER_EXPORT___;
