// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.people-page-container {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.person-container {
  height: 100px;
  width: min(800px, 40%);
  background-color: aqua;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.person-container img {
  width: 65px;
  height: 65px;
}

.person-list-image {
  width: 20%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-list-name {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/peoplePage/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".people-page-container {\n  margin-top: 25px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.person-container {\n  height: 100px;\n  width: min(800px, 40%);\n  background-color: aqua;\n  display: flex;\n  align-items: center;\n  /* justify-content: center; */\n}\n\n.person-container img {\n  width: 65px;\n  height: 65px;\n}\n\n.person-list-image {\n  width: 20%;\n  height: 65px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.person-list-name {\n  display: flex;\n  width: 80%;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
