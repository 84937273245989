// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.messages-container {
  border: 1px solid rgb(187, 180, 180);
  width: 960px;
  height: 70vh;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.message-user {
  padding: 10px;
  box-sizing: border-box;
  width: 80vw;
  min-height: 65px;
  background-color: rgb(187, 180, 180);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.message-friend {
  padding: 10px;
  box-sizing: border-box;
  width: 80vw;
  min-height: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(136, 133, 133);
}

.message-user .message-friend p {
  font-size: 25px;
}

.padding-top {
  width: 100%;
  height: 100%;
  margin-top: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/chat/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,2BAA2B;EAC3B,8BAA8B;EAC9B,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".message-page-container {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  height: auto;\n}\n\n.messages-container {\n  border: 1px solid rgb(187, 180, 180);\n  width: 960px;\n  height: 70vh;\n  display: flex;\n  flex-direction: column-reverse;\n  gap: 10px;\n  overflow: auto;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n.message-user {\n  padding: 10px;\n  box-sizing: border-box;\n  width: 80vw;\n  min-height: 65px;\n  background-color: rgb(187, 180, 180);\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.message-friend {\n  padding: 10px;\n  box-sizing: border-box;\n  width: 80vw;\n  min-height: 50px;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  align-items: center;\n  background-color: rgb(136, 133, 133);\n}\n\n.message-user .message-friend p {\n  font-size: 25px;\n}\n\n.padding-top {\n  width: 100%;\n  height: 100%;\n  margin-top: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
