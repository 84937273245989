import React from "react";
import { useNavigate } from "react-router-dom";

const FriendRequestItem = ({
  request,
  AcceptFriendRequest,
  RejectFriendRequest,
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        marginTop: "15px",
        display: "flex",
        backgroundColor: "#a9d8b8",
        justifyContent: "space-between",
        width: "600px",
        height: "130px",
      }}
    >
      {request["username"]}
      <div>
        <button
          onClick={() =>
            AcceptFriendRequest(request["sender_id"], request["request_id"])
          }
          style={{ color: "green", height: "20px", width: "80px" }}
        >
          Accept
        </button>
        <button
          onClick={() => RejectFriendRequest()}
          style={{ color: "red", height: "20px", width: "80px" }}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default FriendRequestItem;
