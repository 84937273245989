import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import Navbar from "scenes/navbar";
import Map from "./Map";
import { useSelector } from "react-redux";
import Comment from "components/Comment/Comment";
import CommentForm from "components/CommentForm";
import Error from "components/Error";
import { ReactComponent as ArrowDown } from "./down-arrow-svgrepo-com.svg";
import AlbumsDropdown from "components/albumsDropdown";
import PhotoInformationsCard from "components/photoInformationsCard";
import { ReactComponent as HideIcon } from "../../assets/icons/hide-svgrepo-com.svg";
import { ReactComponent as ShowIcon } from "../../assets/icons/show-svgrepo-com.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-1487-svgrepo-com.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/save-svgrepo-com.svg";
import { ReactComponent as AddLocationIcon } from "../../assets/icons/location-plus-svgrepo-com.svg";
import { ReactComponent as UnsaveIcon } from "../../assets/icons/minus-svgrepo-com.svg";

const PhotoPage = () => {
  const navigate = useNavigate();
  const { photoId } = useParams(); // Actually it is aws_filename, but if I change that it doesn't work
  const user_id = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const faceId = searchParams.get("faceId");
  const Box0 = searchParams.get("Box0");
  const Box1 = searchParams.get("Box1");
  const Box2 = searchParams.get("Box2");
  const Box3 = searchParams.get("Box3");
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [searchFaceDownsizeRatio, setSearchFaceDownsizeRatio] = useState("");
  const [imageComments, setImageComments] = useState([]);
  const rootComments = imageComments?.filter(
    (comment) => comment?.["parent_id"] === null
  );
  const [albumsDropdown, setAlbumsDropdown] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const [photoData, setPhotoData] = useState([]);
  const [facesArray, setFacesArray] = useState([]);
  const [showBoxes, setShowBoxes] = useState(true);
  const [simillarFaces, setSimmilarFaces] = useState([]);
  const [mainPhoto, setMainPhoto] = useState("");
  const [assignDropdown, setAssignDropdown] = useState(false);
  const [activeComment, setActiveComment] = useState(null);
  const [descriptionText, setDescriptionText] = useState("");
  const [photoName, setPhotoName] = useState("");
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [libraries] = useState(["places"]);
  const [error, setError] = useState("");
  const [lngLat, setLngLat] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [descriptionHover, setDescriptionHover] = useState(false);
  const [photoInformations, setPhotoInformations] = useState({});
  const [selectedFace, setSelectedFace] = useState("");
  const [selectedPersonInfo, setSelectedPersonInfo] = useState({});
  const [loadingPeople, setLoadingPeople] = useState([]);
  const [assignedTrigger, setAssignTrigger] = useState(false);
  const [usersPeople, setUsersPeople] = useState([]);
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [photoLocationInformations, setPhotoLocationInformations] = useState(
    []
  );
  const [removeAssignmentDropdown, setRemoveAssignmentDropdown] =
    useState(false);
  const [usersPeopleOffset, setUsersPeopleOffset] = useState(0);

  //Unused
  const [isEditWhenTaken, setIsEditWhenTaken] = useState(false);
  const [whenTakenHover, setWhenTakenHover] = useState(false);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setUsersPeopleOffset((curr) => curr + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingPeople]
  );

  useEffect(() => {
    console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    if (usersPeopleOffset !== 0) {
      getUsersPeople();
    }
  }, [usersPeopleOffset]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCFBfoJFkHuYneNCu-a0QELQIrr9eB_Sio",
    libraries,
  });

  const getReplies = (commentId) => {
    return imageComments
      .filter((comment) => comment["parent_id"] === commentId)
      .sort(
        (a, b) =>
          new Date(a["created_at"]).getTime() -
          new Date(b["created_at"]).getTime()
      );
  };

  const removeSavedPhoto = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/save/${photoId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsSaved(false);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const savePhoto = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/save/${photoId}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsSaved(true);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const addComment = async (commentContent, parent) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/comment`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            content: commentContent,
            aws_filename: photoId,
            parent_id: parent,
          }),
        }
      );
      const temp = await res.json();
      setImageComments([temp, ...imageComments]);
      setActiveComment(null);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const deletePhoto = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/${photoId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `BEARER ${token}`,
          },
        }
      );
      if (res.status === 204) {
        navigate("/photoGallery");
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const setCoordinates = (coords) => {
    setLngLat(coords);
  };

  const deleteComment = async (commentId) => {
    try {
      if (!window.confirm("Are you sure you want to delete comment?")) {
        return "Missclick";
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/comment/${commentId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedComments = imageComments?.filter(
        (comment) => comment["comment_id"] !== commentId
      );
      setImageComments(updatedComments);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const getComments = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/comments/${photoId}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(imageComments);
    if (res.status !== 200) {
      setImageComments([]);
      return;
    }
    const comments = await res.json();
    setImageComments(comments);
  };

  const updateComment = async (text, commentId) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/comment/${commentId}`,
        {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ content: text }),
        }
      );
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const setImageLocation = async () => {
    try {
      console.log(lngLat, "lnglat");
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/location/${photoId}`,
        {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ lat: lngLat["lat"], lng: lngLat["lng"] }),
        }
      );
      return await res.json();
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const getSelectedPersonInfo = async () => {
    try {
      if (!selectedFace) {
        return "No face chosen";
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/face_id/${selectedFace}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status !== 200) {
        setSelectedPersonInfo({});
        return;
      }
      const temp = await res.json();
      setSelectedPersonInfo(temp);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const getUsersPeople = async () => {
    try {
      setLoadingPeople(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/${user_id}/people/?offset=${usersPeopleOffset}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status !== 200) {
        setUsersPeople([]);
        return;
      }
      const people = (await res.json())["data"];
      setUsersPeople((curr) => [...curr, ...people]);
      setLoadingPeople(false);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const getImageComments = async () => {};

  useEffect(() => {
    getSelectedPersonInfo();
  }, [assignedTrigger]);

  useEffect(() => {
    getUsersPeople();
    getComments();
  }, []);

  useEffect(() => {
    if (!selectedFace) {
      return;
    }
    getSelectedPersonInfo();
  }, [selectedFace]);

  useEffect(() => {
    setUsersPeople(usersPeople);
  }, [usersPeople]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setBoxes([]);
        setFacesArray([]);
        await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/1000x600/${photoId}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => {
            return response.blob();
          })
          .then((blob) => setMainPhoto(URL.createObjectURL(blob)));
        const res = await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/boxes/${photoId}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `BEARER ${token}`,
            },
          }
        );
        const photo_data = await res.json();
        if (res.status !== 200) {
          return;
        }
        const downsizeRatio =
          Math.round(photo_data?.["original_size"]?.[1] / 6) / 100 || null;
        setSearchFaceDownsizeRatio(downsizeRatio);
        setFacesArray(photo_data["faces"]);
        const boxes_600px = photo_data["faces"].map((face) => {
          return face["position_on_image"].map((value) => {
            value = Math.round(value / downsizeRatio);
            return value;
          });
        });

        setBoxes(boxes_600px);
        const res_photo = await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/${photoId}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const informations = await res_photo.json();
        console.log(informations);
        setPhotoInformations(informations["informations"]);
        setPhotoLocationInformations(informations["location"]);
        setIsSaved(informations["is_saved"]["is_saved"]);
        getImageComments();
      } catch ({ name, message }) {
        console.log(message);
        setError(message);
      }
    };
    fetchData();
  }, [saveTrigger, photoId]);
  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      {isMapOpen && (
        <div
          style={{
            position: "fixed",
            zIndex: "8",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => setIsMapOpen(false)}
            style={{ position: "absolute", right: "10px", top: "10px" }}
          >
            Close Map
          </button>
          {isLoaded ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Map setCoordinates={setCoordinates} />
              <button
                style={{ marginBottom: "50px", padding: "4px" }}
                onClick={() => setImageLocation()}
              >
                Set Location
              </button>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      )}
      <div className="photo-page-container">
        <div className="photo-info-buttons">
          <div className="boxes-container">
            <img className="photo" src={mainPhoto} />
            {showBoxes &&
              (faceId ? (
                <div
                  style={{
                    zIndex: 6,
                    height: Box3 / searchFaceDownsizeRatio + "px",
                    width: Box2 / searchFaceDownsizeRatio + "px",
                    position: "absolute",
                    left: Box0 / searchFaceDownsizeRatio + "px",
                    top: Box1 / searchFaceDownsizeRatio + "px",
                    borderStyle: "solid",
                    borderColor: "grey",
                    cursor: "pointer",
                  }}
                ></div>
              ) : (
                boxes?.map((box, index) => {
                  return (
                    <div
                      className={
                        facesArray[index]["face_id"] === selectedFace
                          ? "selected-face"
                          : "not-selected-face"
                      }
                      style={{
                        zIndex: 6,
                        height: box[3] + "px",
                        width: box[2] + "px",
                        position: "absolute",
                        left: box[0] + "px",
                        top: box[1] + "px",
                        borderStyle: "solid",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        {
                          setSelectedFace(facesArray[index]["face_id"]);
                        }
                      }}
                    ></div>
                  );
                })
              ))}
          </div>

          <div style={{ display: "flex", gap: "5px" }}>
            {console.log(photoInformations["owner"], user_id)}
            {photoInformations["owner"] === user_id && (
              <>
                <button
                  className="my-button-delete"
                  onClick={() => deletePhoto()}
                >
                  Delete{" "}
                  <span>
                    <DeleteIcon />
                  </span>
                </button>
                <button
                  className="my-button"
                  onClick={() => setIsMapOpen(true)}
                >
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "17px",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    Choose Image Location{" "}
                    <span>
                      <AddLocationIcon />
                    </span>
                  </p>
                </button>
                <AlbumsDropdown photoFilename={photoId} />
              </>
            )}
            {showBoxes ? (
              <button className="my-button" onClick={() => setShowBoxes(false)}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  Hide boxes
                  <span>
                    <HideIcon />
                  </span>
                </p>
              </button>
            ) : (
              <button className="my-button" onClick={() => setShowBoxes(true)}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  Show Boxes{" "}
                  <span>
                    <ShowIcon />
                  </span>
                </p>
              </button>
            )}
            {!isUserOwner &&
              (isSaved ? (
                <button
                  className="my-button"
                  onClick={() => {
                    removeSavedPhoto();
                    setSaveTrigger((curr) => !curr);
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    Unsave Photo
                    <span>
                      <UnsaveIcon />
                    </span>
                  </p>
                </button>
              ) : (
                <button
                  className="my-button"
                  onClick={() => {
                    savePhoto();
                    setSaveTrigger((curr) => !curr);
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    Save Photo{" "}
                    <span>
                      <SaveIcon />
                    </span>
                  </p>
                </button>
              ))}
          </div>

          <PhotoInformationsCard
            person={selectedPersonInfo}
            photo={photoInformations}
            faceId={selectedFace}
            locationInformations={photoLocationInformations}
            setAssignTrigger={setAssignTrigger}
          />
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3 className="comments-title">Comments</h3>
          <div className="comment-form-title">Write comment</div>
          <CommentForm
            submitLabel="Write"
            handleSubmit={addComment}
            token={token}
            photoId={photoId}
          />
          <div style={{ marginTop: "20px" }}>
            {rootComments.map((comment) => {
              return (
                <div key={comment["comment_id"]}>
                  <Comment
                    comment={comment}
                    replies={getReplies(comment["comment_id"])}
                    currentUserId={userId}
                    deleteComment={deleteComment}
                    activeComment={activeComment}
                    updateComment={updateComment}
                    setActiveComment={setActiveComment}
                    addComment={addComment}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoPage;
