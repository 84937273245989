// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssignPersonItem_person-to-assign-container__37T03 {
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background-color: gainsboro;
}

.AssignPersonItem_person-image-container__0aJZd {
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssignPersonItem_person-image-container__0aJZd img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.AssignPersonItem_person-name-container__z9p7j {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/asignPersonItem/AssignPersonItem.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".person-to-assign-container {\n  height: 50px;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  cursor: pointer;\n  background-color: gainsboro;\n}\n\n.person-image-container {\n  height: 100%;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.person-image-container img {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  object-fit: cover;\n}\n\n.person-name-container {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"person-to-assign-container": `AssignPersonItem_person-to-assign-container__37T03`,
	"person-image-container": `AssignPersonItem_person-image-container__0aJZd`,
	"person-name-container": `AssignPersonItem_person-name-container__z9p7j`
};
export default ___CSS_LOADER_EXPORT___;
