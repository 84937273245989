// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .error-container {
  height: 70px;
  min-width: 240px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(202, 62, 62);
  font-size: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
} */

.error-container {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 350px;
  height: 130px;
  background-color: firebrick;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-container span {
  color: white;
}

.close-button-container {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.close-button {
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Error/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;GAcG;;AAEH;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["/* .error-container {\n  height: 70px;\n  min-width: 240px;\n  max-width: 600px;\n  margin-left: auto;\n  margin-right: auto;\n  background-color: rgb(202, 62, 62);\n  font-size: 25px;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 15px;\n  margin-bottom: 15px;\n} */\n\n.error-container {\n  position: fixed;\n  bottom: 40px;\n  right: 40px;\n  width: 350px;\n  height: 130px;\n  background-color: firebrick;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.error-container span {\n  color: white;\n}\n\n.close-button-container {\n  position: absolute;\n  height: 20px;\n  width: 20px;\n  top: 15px;\n  right: 15px;\n  cursor: pointer;\n}\n\n.close-button {\n  width: 20px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
