import React from 'react';
import Background from './photo_album_background.jpg';
import { useState } from 'react';
import LoginForm from './Form';
import { useNavigate } from 'react-router-dom';
import styles from './LoginPage.module.css';

const LoginPage = () => {
  const [isLogin, setisLogin] = useState(true);
  const navigate = useNavigate();
  if (isLogin) {
    return (
      <div
        className="w-full h-full min-h-screen flex items-center justify-center"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
        }}
      >
        <LoginForm />
      </div>
    );
  } else {
    return undefined;
  }
};

export default LoginPage;
