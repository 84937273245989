// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrentPageInfo_CurrentPageInfo__UoQhY {
  padding-left: 180px;
  padding-bottom: 5px;
  margin-bottom: 40px;
  margin-top: 30px;
  height: 50px;
  font-size: 30px;
  color: #282d2e;
  border-bottom: solid 1px #d1e6fa;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/CurrentPageInfo/CurrentPageInfo.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,gCAAgC;EAChC,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".CurrentPageInfo {\n  padding-left: 180px;\n  padding-bottom: 5px;\n  margin-bottom: 40px;\n  margin-top: 30px;\n  height: 50px;\n  font-size: 30px;\n  color: #282d2e;\n  border-bottom: solid 1px #d1e6fa;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CurrentPageInfo": `CurrentPageInfo_CurrentPageInfo__UoQhY`
};
export default ___CSS_LOADER_EXPORT___;
