import React from "react";
import Navbar from "scenes/navbar";
import FaqStyles from "./Faq.module.css";
import Footer from "components/footer";

const Faq = () => {
  const faqData = [
    {
      question: "How do I upload family photos?",
      answer:
        'To upload family photos, go to the "Upload" section in your dashboard and follow the instructions to add pictures to your family album.',
    },
    {
      question: "Can I create multiple family albums?",
      answer:
        'Yes, you can create multiple family albums. Visit the "Albums" tab and use the "Create Album" option to organize your photos into different categories.',
    },
    {
      question: "How can I share a family photo with relatives?",
      answer:
        'To share a family photo, open the photo in your album and use the "Share" button. You can share it via email or generate a shareable link.',
    },
    // Add more FAQ entries as needed
  ];
  return (
    <>
      <Navbar />
      <div className={FaqStyles["faq-container"]}>
        <h1>Frequently Asked Questions</h1>
        <div className={FaqStyles["faq-list"]}>
          {faqData.map((faq, index) => (
            <div key={index} className={FaqStyles["faq-item"]}>
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
