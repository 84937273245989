// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GalleryItemSkeleton_skeleton__mTpmO {
  animation: GalleryItemSkeleton_skeleton-loading__WETQD 1s linear infinite alternate;
}

@keyframes GalleryItemSkeleton_skeleton-loading__WETQD {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/skeletons/galleryItemSkeleton/GalleryItemSkeleton.module.css"],"names":[],"mappings":"AAAA;EACE,mFAAwD;AAC1D;;AAEA;EACE;IACE,oCAAoC;EACtC;EACA;IACE,oCAAoC;EACtC;AACF","sourcesContent":[".skeleton {\n  animation: skeleton-loading 1s linear infinite alternate;\n}\n\n@keyframes skeleton-loading {\n  0% {\n    background-color: hsl(200, 20%, 80%);\n  }\n  100% {\n    background-color: hsl(200, 20%, 95%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": `GalleryItemSkeleton_skeleton__mTpmO`,
	"skeleton-loading": `GalleryItemSkeleton_skeleton-loading__WETQD`
};
export default ___CSS_LOADER_EXPORT___;
