// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonItemSkeleton_skeleton-container__Ilbj\\+ {
  border: 1px solid #cccccc;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;
}

.PersonItemSkeleton_content-wrapper__nbESk {
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PersonItemSkeleton_image__XYQoj {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.PersonItemSkeleton_name__ARh3A {
  width: 80px;
  height: 18px;
}

.PersonItemSkeleton_dots__eHAMQ {
  height: 35px;
  width: 15px;
}

@keyframes PersonItemSkeleton_shine__WP4UJ {
  to {
    background-position-x: -200%;
  }
}

.PersonItemSkeleton_image__XYQoj,
.PersonItemSkeleton_name__ARh3A,
.PersonItemSkeleton_dots__eHAMQ {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: PersonItemSkeleton_shine__WP4UJ 2s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/skeletons/personItemSkeleton/PersonItemSkeleton.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;;;EAGE,yEAAyE;EACzE,kBAAkB;EAClB,0BAA0B;EAC1B,6DAAmC;AACrC","sourcesContent":[".skeleton-container {\n  border: 1px solid #cccccc;\n  padding: 15px;\n  box-sizing: border-box;\n  border-radius: 8px;\n  margin-bottom: 10px;\n}\n\n.content-wrapper {\n  width: 450px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.image {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n}\n\n.name {\n  width: 80px;\n  height: 18px;\n}\n\n.dots {\n  height: 35px;\n  width: 15px;\n}\n\n@keyframes shine {\n  to {\n    background-position-x: -200%;\n  }\n}\n\n.image,\n.name,\n.dots {\n  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);\n  border-radius: 5px;\n  background-size: 200% 100%;\n  animation: shine 2s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton-container": `PersonItemSkeleton_skeleton-container__Ilbj+`,
	"content-wrapper": `PersonItemSkeleton_content-wrapper__nbESk`,
	"image": `PersonItemSkeleton_image__XYQoj`,
	"name": `PersonItemSkeleton_name__ARh3A`,
	"dots": `PersonItemSkeleton_dots__eHAMQ`,
	"shine": `PersonItemSkeleton_shine__WP4UJ`
};
export default ___CSS_LOADER_EXPORT___;
