import { Box, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Formik } from 'formik'
import Background from './register_background.jpg'
import DropzoneComponent from './DropzoneComponent'
import { Button, message } from 'antd'

const registerSchema = yup.object().shape({
  firstName: yup.string().required('required'),
  lastName: yup.string().required('required'),
  email: yup.string().email('Invalid Email').required('required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters long')
    .max(40, 'Password can not be longer than 40 characters')
    .required('required'),
  username: yup
    .string()
    .min(4, 'Username must be at least 4 characters long')
    .max(40, 'Username can not be longer than 40 characters')
    .required('required'),
})

const initialValuesRegister = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  username: '',
}

const RegisterPage = () => {
  const navigate = useNavigate()
  const [picture, setPicture] = useState([])

  const register = async (values, onSubmitProps, setErrors) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/register`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            email: values['email'],
            password: values['password'],
            first_name: values['firstName'],
            last_name: values['lastName'],
            username: values['username'],
          }),
        }
      )

      const fullRes = await res.json()
      console.log(fullRes, picture)

      if (res.status === 409) {
        message.error(fullRes.detail)
        if (fullRes === 'Account with that email already exists') {
          onSubmitProps.setErrors({
            email: 'Account with that email already exists',
          })
          message.error('Account with that email already exists')
          return 1
        }
        if (fullRes === 'Account with that username already exists') {
          onSubmitProps.setErrors({
            username: 'Account with that username already exists',
          })
          message.error('Account with that username already exists')

          return 1
        }
      }

      // if (picture?.name?.length) {
      //   const token = fullRes["token"];
      //   const data = new FormData();
      //   data.append("file", picture);
      //   await axios
      //     .post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/token/profile_picture`, data, {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         cors: true,
      //         Authorization: `Bearer ${token}`,
      //       },
      //     })
      //     .catch((e) => {
      //       console.log(e.response);
      //     });
      // }
      if (res.status === 200) {
        navigate('/')
      }
    } catch ({ name }) {
      message.error('Failed to register!')
    }
  }

  // const handleFormSubmit = async (values, onSubmitProps, { setErrors }) => {
  //   await register(values, onSubmitProps, setErrors)
  // }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '0px 20px',
      }}
    >
      <div
        style={{
          background: 'white',
          padding: '30px 40px',
          borderRadius: '20px',
        }}
      >
        <h2
          className="font-[Poppins] text-center text-2xl font-bold pt-2"
          style={{ padding: '8px', textAlign: 'center', marginBottom: '20px' }}
        >
          Welcome to Family Photos!!!
        </h2>

        <Formik
          onSubmit={register}
          initialValues={initialValuesRegister}
          validationSchema={registerSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: 'grid',
                  gap: '30px',
                  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: '500px',
                }}
              >
                <TextField
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={
                    Boolean(touched.firstName) && Boolean(errors.firstName)
                  }
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={Boolean(touched.username) && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: 'span 4' }}
                />
                <div style={{ gridColumn: 'span 4' }}>
                  <DropzoneComponent setProfilePicture={setPicture} />
                </div>
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 4', width: '100%' }}
                />
                <TextField
                  type="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: 'span 4', width: '100%' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    gridColumn: 'span 4',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                      padding: '1rem',
                      marginBottom: '1rem',
                      height: '56px',
                    }}
                  >
                    REGISTER
                  </Button>
                  <div>
                    Already have an account?{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        navigate('/login')
                        resetForm()
                      }}
                    >
                      Log in here.
                    </span>
                  </div>
                </Box>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default RegisterPage
