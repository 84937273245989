// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page-container {
  display: flex;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  padding-top: 40px;
  gap: 25px;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .profile-page-container {
    flex-direction: column;
  }
}

@media screen and (min-width: 1000px) {
  .profile-page-container {
    flex-direction: row;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/profilePage/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".profile-page-container {\n  display: flex;\n  justify-content: center;\n  padding: 15px;\n  box-sizing: border-box;\n  padding-top: 40px;\n  gap: 25px;\n  align-items: center;\n}\n\n@media screen and (max-width: 1000px) {\n  .profile-page-container {\n    flex-direction: column;\n  }\n}\n\n@media screen and (min-width: 1000px) {\n  .profile-page-container {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
