// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterPage_dropzone-box__SoZ34 {
  border: 2px dashed green;
  padding: 1rem;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/registerPage/RegisterPage.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".dropzone-box {\n  border: 2px dashed green;\n  padding: 1rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone-box": `RegisterPage_dropzone-box__SoZ34`
};
export default ___CSS_LOADER_EXPORT___;
