// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes FoundUserSkeleton_shimmer__GezwH {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}

.FoundUserSkeleton_skeleton-container__IKh20 {
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.FoundUserSkeleton_content-wrapper__rcPVl {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 450px;
}

.FoundUserSkeleton_profile-picture__v4VAo {
  border-radius: 50%;
  /* background-color: #cccccc; */
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.FoundUserSkeleton_name__cIe14 {
  font-size: 1rem;
  font-weight: bold;
  width: 130px;
  height: 23px;
  /* background-color: #cc  cccc; */
}

@keyframes FoundUserSkeleton_shine__vDIXD {
  to {
    background-position-x: -200%;
  }
}

.FoundUserSkeleton_name__cIe14,
.FoundUserSkeleton_profile-picture__v4VAo {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: FoundUserSkeleton_shine__vDIXD 2s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/skeletons/foundUserSkeleton/FoundUserSkeleton.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;;EAEE,yEAAyE;EACzE,kBAAkB;EAClB,0BAA0B;EAC1B,4DAAmC;AACrC","sourcesContent":["@keyframes shimmer {\n  0% {\n    background-position: -450px 0;\n  }\n  100% {\n    background-position: 450px 0;\n  }\n}\n\n.skeleton-container {\n  padding: 15px;\n  box-sizing: border-box;\n  margin-bottom: 15px;\n  border: 1px solid #cccccc;\n  border-radius: 8px;\n}\n\n.content-wrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  width: 450px;\n}\n\n.profile-picture {\n  border-radius: 50%;\n  /* background-color: #cccccc; */\n  width: 50px;\n  height: 50px;\n  margin-right: 15px;\n}\n\n.name {\n  font-size: 1rem;\n  font-weight: bold;\n  width: 130px;\n  height: 23px;\n  /* background-color: #cc  cccc; */\n}\n\n@keyframes shine {\n  to {\n    background-position-x: -200%;\n  }\n}\n\n.name,\n.profile-picture {\n  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);\n  border-radius: 5px;\n  background-size: 200% 100%;\n  animation: shine 2s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton-container": `FoundUserSkeleton_skeleton-container__IKh20`,
	"content-wrapper": `FoundUserSkeleton_content-wrapper__rcPVl`,
	"profile-picture": `FoundUserSkeleton_profile-picture__v4VAo`,
	"name": `FoundUserSkeleton_name__cIe14`,
	"shine": `FoundUserSkeleton_shine__vDIXD`,
	"shimmer": `FoundUserSkeleton_shimmer__GezwH`
};
export default ___CSS_LOADER_EXPORT___;
